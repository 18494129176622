import React from "react";
import ReactSelect from "react-select";

type SelectProps = React.ComponentProps<typeof ReactSelect>;

export type SelectPropsWithOptionalOptions = Omit<SelectProps, "options"> & {
  loadOptions?: Partial<Pick<SelectProps, "options">>;
};

export const Select = ReactSelect;

export default ReactSelect;
