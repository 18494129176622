import { Textarea } from "@rebass/forms";
import styled from "styled-components";

export const TextArea = styled(Textarea)`
  border: 1px solid #cccccc !important;
  background: #ffffff !important;
  border-radius: 4px;
  font: inherit;
  transition: all 100ms;

  &:hover {
    border: 1px solid ${(props: any) => (props.isInvalid ? "red" : "#b3b3b3")} !important;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px
      ${(props: any) => (props.isInvalid ? "red" : "#2684ff")};
    outline: 0 !important;
    border-color: ${(props: any) =>
      props.isInvalid ? "red" : "#2684ff"} !important;
  }

  &:disabled {
    background: #eaeaea !important;
    cursor: not-allowed;
  }
`;

export default TextArea;
