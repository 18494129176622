import { createGlobalStyle } from "styled-components";

import NunitoSansBlackWoff from "./fonts/NunitoSans-Black.woff";
import NunitoSansBlackItalicWoff from "./fonts/NunitoSans-BlackItalic.woff";
import NunitoSansBoldWoff from "./fonts/NunitoSans-Bold.woff";
import NunitoSansBoldItalicWoff from "./fonts/NunitoSans-BoldItalic.woff";
import NunitoSansExtraBoldWoff from "./fonts/NunitoSans-ExtraBold.woff";
import NunitoSansExtraBoldItalicWoff from "./fonts/NunitoSans-ExtraBoldItalic.woff";
import NunitoSansExtraLightWoff from "./fonts/NunitoSans-ExtraLight.woff";
import NunitoSansExtraLightItalicWoff from "./fonts/NunitoSans-ExtraLightItalic.woff";
import NunitoSansItalicWoff from "./fonts/NunitoSans-Italic.woff";
import NunitoSansLightWoff from "./fonts/NunitoSans-Light.woff";
import NunitoSansLightItalicWoff from "./fonts/NunitoSans-LightItalic.woff";
import NunitoSansRegularWoff from "./fonts/NunitoSans-Regular.woff";
import NunitoSansSemiBoldWoff from "./fonts/NunitoSans-SemiBold.woff";
import NunitoSansSemiBoldItalicWoff from "./fonts/NunitoSans-SemiBoldItalic.woff";

export const GlobalBaseStyle = createGlobalStyle`
html, body {
    min-height: initial;
    width: 100%;
    font-family: 'Nunito Sans SemiBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(238, 244, 248);
    color: #232D42;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


@font-face {
    font-family: 'Nunito Sans Regular';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Regular'), url(${NunitoSansRegularWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Italic'), url(${NunitoSansItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraLight';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraLight'), url(${NunitoSansExtraLightWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraLight Italic'), url(${NunitoSansExtraLightItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Light';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Light'), url(${NunitoSansLightWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Light Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Light Italic'), url(${NunitoSansLightItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans SemiBold'), url(${NunitoSansSemiBoldWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans SemiBold Italic'), url(${NunitoSansSemiBoldItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Bold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Bold'), url(${NunitoSansBoldWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Bold Italic'), url(${NunitoSansBoldItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraBold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraBold'), url(${NunitoSansExtraBoldWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraBold Italic'), url(${NunitoSansExtraBoldItalicWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Black';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Black'), url(${NunitoSansBlackWoff}) format('woff');
}


@font-face {
    font-family: 'Nunito Sans Black Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Black Italic'), url(${NunitoSansBlackItalicWoff}) format('woff');
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slidein {
	0% {
		width: 0px;
	}
	100% {
		width: 120px;
	}
}

.ui.dropdown {
    cursor: pointer;
    position: relative;
    /* display: inline-block; */
    display: flex;
    outline: 0;
    text-align: left;
    transition: box-shadow .1s ease,width .1s ease;
    -webkit-tap-highlight-color: transparent;
}
.ui.dropdown>.text {
    margin-right: 7px;
}
.ui.upward.dropdown>.menu {
    top: auto;
    bottom: 100%;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.08);
    border-radius: .28571429rem .28571429rem 0 0;
}
.ui.dropdown .menu {
    /* left: 0; */
    right: 0;
}
.ui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: max-content;
    margin: 0;
    padding: 0 0;
    background: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: opacity .1s ease;
    z-index: 11;
    will-change: transform,opacity;
}
.visible.transition {
    display: block!important;
    visibility: visible!important;
}
.transition {
    animation-iteration-count: 1;
    animation-duration: .3s;
    animation-timing-function: ease;
    animation-fill-mode: both;
}

/* .ui.dropdown>.dropdown.icon:before {
    content: '\f0d7';
} */

.ui.dropdown .menu>.item:first-child {
    border-top-width: 0;
}

.ui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0,0,0,.87);
    padding: .78571429rem 1.14285714rem!important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
}
.ui.dropdown .menu>* {
    white-space: nowrap;
}

.ui.dropdown .menu>.item>.description, .ui.dropdown>.text>.description {
    float: right;
    margin: 0 0 0 1em;
    color: rgba(0,0,0,.4);
}

/* modal transitioning */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  transform: translateY(48px);
  transition: transform 150ms ease-out;
}
.ReactModal__Content--after-open {
  transform: translateY(0);
}
.ReactModal__Content--before-close {
  transform: translateY(-48px);
}

/* Google Places Autocomplete react-google-autocomplete */
.pac-autocomplete {
  z-index: 9001;
}
`;

export default GlobalBaseStyle;
