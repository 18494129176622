import { Spinner3 } from "@styled-icons/evil/Spinner3";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const InlineSpinner = styled(Spinner3)`
  animation: 1s linear ${spin} infinite;
`;

export default InlineSpinner;
