import { ApolloClient, PossibleTypesMap, TypePolicies } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { DEFAULT_LANG } from "../../i18n";
import { buildAnalyticsClient } from "../apollo/buildAnalyticsClient";
import { buildAppClient, buildAppCache } from "../apollo/buildAppClient";
import { buildAuthClient } from "../apollo/buildAuthClient";

export const EVENT_NAME = "auth-state-update";

export type MSALConfig = {
  clientId: string;
  redirectUri: string;
};

export type GoogleConfig = {
  clientId: string;
};

export class GlobalSessionStorage {
  storagePrefix: string;
  possibleTypes: PossibleTypesMap;
  tokenName: string;
  refreshTokenName: string;
  refreshTokenTimeName: string;
  sessionMetadataName: string;
  hasAuthenticatedSuccessfully: boolean;
  productPathSegment: string;
  msalConfig?: MSALConfig;
  googleConfig?: GoogleConfig;
  appTypePolicies?: TypePolicies;

  clients: {
    [key: string]: ApolloClient<any>;
    auth: ApolloClient<any>;
    analytics: ApolloClient<any>;
    app: ApolloClient<any>;
  };

  constructor(
    productPathSegment: string,
    possibleTypes: PossibleTypesMap,
    storagePrefix: string = "skodel-",
    msalConfig?: MSALConfig,
    googleConfig?: GoogleConfig,
    appTypePolicies?: TypePolicies,
    blacklistedForBatching?: string[]
  ) {
    this.possibleTypes = possibleTypes;
    this.storagePrefix = storagePrefix;
    this.tokenName = this.storagePrefix + "auth-token";
    this.refreshTokenName = this.storagePrefix + "refresh-token";
    this.refreshTokenTimeName = this.storagePrefix + "refresh-token-time";
    this.sessionMetadataName = this.storagePrefix + "metadata";
    this.productPathSegment = productPathSegment;
    this.msalConfig = msalConfig;
    this.googleConfig = googleConfig;
    this.appTypePolicies = appTypePolicies;

    this.hasAuthenticatedSuccessfully = this.token !== null;
    const appCache = buildAppCache({
      possibleTypes: this.possibleTypes || {},
      typePolicies: this.appTypePolicies,
    });

    this.clients = {
      analytics: buildAnalyticsClient(),
      auth: buildAuthClient({}),
      app: buildAppClient({
        appCache,
        useErrorLink: true,
        blacklistedForBatching,
      }),
    };
  }

  onTokenError() {
    this.hasAuthenticatedSuccessfully = false;
    this.dispatchAuthStateUpdateEvent("tokenError");
  }

  getBaseHost() {
    return process.env.NODE_ENV === "production"
      ? "https://api.skodel.com"
      : "http://127.0.0.1:5000";
  }

  getProductPathSegment() {
    return this.productPathSegment;
  }

  login(token: string, refreshToken: string | null = null) {
    this.sessionMetadata = null;
    this.token = token;
    if (refreshToken) {
      this.refreshToken = refreshToken;
      this.refreshTokenTime = Math.floor(new Date().getTime() / 1000) + "";
    } else {
      this.refreshToken = null;
      this.refreshTokenTime = null;
    }
    this.hasAuthenticatedSuccessfully = true;

    this.dispatchAuthStateUpdateEvent("loggedIn");

    toast.success("Welcome to Skodel.");

    // this will reload all components with 'active' queries on the page
    this.clients.app.refetchQueries({
      include: "active",
    });
  }

  dispatchAuthStateUpdateEvent(authEventType: string) {
    const e = new CustomEvent(EVENT_NAME, {
      detail: {
        authEventType,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    window.dispatchEvent(e);
  }

  logout(
    i18n: any,
    options?: {
      redirectPath?: any | null;
      showMessage?: boolean;
      t?: any;
      history?: any;
    }
  ) {
    const optionsWithDefaults = {
      redirectPath: "/",
      showMessage: true,
      ...options,
    };

    // @ts-ignore
    this.token = null;
    this.refreshToken = null;
    this.refreshTokenTime = null;
    this.sessionMetadata = null;
    this.hasAuthenticatedSuccessfully = false;

    i18n.changeLanguage(DEFAULT_LANG);

    if (optionsWithDefaults.showMessage === true && optionsWithDefaults.t) {
      toast.success(
        optionsWithDefaults.t("auth-logged-out-message", {
          defaultValue: "Successfully logged out of Skodel.",
        })
      );
    }

    if (
      optionsWithDefaults.redirectPath !== null &&
      optionsWithDefaults.history
    ) {
      optionsWithDefaults.history.push(optionsWithDefaults.redirectPath);
    }

    this.dispatchAuthStateUpdateEvent("loggedOut");

    return;
  }

  get token() {
    // @ts-ignore
    return localStorage.getItem(this.tokenName) || null;
  }

  set token(value: string) {
    if (value === null) {
      localStorage.removeItem(this.tokenName);
    } else {
      localStorage.setItem(this.tokenName, value);
    }
  }

  get sessionMetadata() {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(this.sessionMetadataName)) || null;
  }

  set sessionMetadata(value: {} | null) {
    // @ts-ignore
    if (value === null) {
      localStorage.removeItem(this.sessionMetadataName);
    } else {
      localStorage.setItem(this.sessionMetadataName, JSON.stringify(value));
    }
  }

  get refreshToken() {
    // @ts-ignore
    return localStorage.getItem(this.refreshTokenName) || null;
  }

  set refreshToken(value: string | null) {
    // @ts-ignore
    if (value === null) {
      localStorage.removeItem(this.refreshTokenName);
    } else {
      localStorage.setItem(this.refreshTokenName, value);
    }
  }

  get refreshTokenTime() {
    // @ts-ignore
    return localStorage.getItem(this.refreshTokenTimeName) || null;
  }

  set refreshTokenTime(value: string | null) {
    // @ts-ignore
    if (value === null) {
      localStorage.removeItem(this.refreshTokenTimeName);
    } else {
      localStorage.setItem(this.refreshTokenTimeName, value);
    }
  }
}

let GlobalSessionStorageInstance: GlobalSessionStorage | null = null;
export const getGlobalSessionStorage: () => GlobalSessionStorage = () => {
  if (!GlobalSessionStorageInstance) {
    throw new Error("GlobalSessionStorageInstance is not setup");
  }
  return GlobalSessionStorageInstance;
};
export const setGlobalSessionStorage = (newInstance: GlobalSessionStorage) => {
  GlobalSessionStorageInstance = newInstance;
};

export const useAppApolloClient: () => ApolloClient<any> = () => {
  return getGlobalSessionStorage().clients.app;
};

export const useAuthApolloClient: () => ApolloClient<any> = () => {
  return getGlobalSessionStorage().clients.auth;
};

export const useHasAuthenticatedSuccessfully = () => {
  const [hasAuthenticatedSuccessfully, setHasAuthenticatedSuccessfully] =
    useState(getGlobalSessionStorage().hasAuthenticatedSuccessfully);

  useEffect(() => {
    const callback = (_: Event) => {
      setHasAuthenticatedSuccessfully(
        getGlobalSessionStorage().hasAuthenticatedSuccessfully
      );
      return false;
    };

    window.addEventListener(EVENT_NAME, callback);

    return () => {
      window.removeEventListener(EVENT_NAME, callback);
    };
  }, [setHasAuthenticatedSuccessfully]);

  return hasAuthenticatedSuccessfully;
};

export const useHasToken = () => {
  const [token, setToken] = useState(getGlobalSessionStorage().token);

  useEffect(() => {
    const callback = (_: Event) => {
      setToken(getGlobalSessionStorage().token);
      return false;
    };

    window.addEventListener(EVENT_NAME, callback);

    return () => {
      window.removeEventListener(EVENT_NAME, callback);
    };
  }, [setToken]);

  return token !== null;
};

export default GlobalSessionStorageInstance;
