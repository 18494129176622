import { ApolloClient, DocumentNode } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import React from "react";

import AsyncSelect from "../async-select";
import { AsyncSelectPropsWithOptionalLoadOptions } from "../async-select";
import { useFormQueryFromContext } from "../query-context";

const organizationPromiseOptions: (
  client: ApolloClient<any>,
  query: DocumentNode,
  inputValue: string
) => any = (client, query, inputValue) => {
  return new Promise((resolve) => {
    client
      .query({
        query,
        variables: {
          query: inputValue,
        },
        fetchPolicy: "network-only",
      })
      .then((state: any) => {
        resolve(
          state.data.organizations.edges.map((edge: any) => ({
            value: edge.node.id,
            label: edge.node.name,
          }))
        );
      });
  });
};

export const OrganizationSelect: React.FC<
  AsyncSelectPropsWithOptionalLoadOptions
> = ({ loadOptions, ...props }) => {
  const client = useApolloClient();
  const GetOrganizationsByName = useFormQueryFromContext(
    "GetOrganizationsByName"
  );

  let loadOptions_: any = loadOptions;

  if (!loadOptions_) {
    loadOptions_ = (inputValue: string) =>
      organizationPromiseOptions(client, GetOrganizationsByName, inputValue);
  }

  return <AsyncSelect defaultOptions loadOptions={loadOptions_} {...props} />;
};

export default OrganizationSelect;
