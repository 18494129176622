import React from "react";
import styled from "styled-components";

import { Flex, FlexProps } from "../../core";

export const NotificationItemBase: React.FC<
  FlexProps & {
    isUnread: boolean;
  }
> = styled(Flex)`
  background: ${(props: any) =>
    props.isUnread ? "rgb(238,244,248)" : "inherit"};
  border-radius: 2px;
  font-size: 0.8em;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;

  &:hover,
  &:active {
    cursor: pointer;
  }
  &:hover {
    background: rgb(233, 239, 243);
  }
  &:active,
  &:focus {
    background: rgb(228, 234, 238);
  }
`;

export default NotificationItemBase;
