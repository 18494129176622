import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { getGlobalSessionStorage } from "../session-provider/session-storage";

export const buildAuthClient = (_options?: {}) => {
  const cache = new InMemoryCache({});

  return new ApolloClient({
    cache,
    link: new HttpLink({
      uri: () => `${getGlobalSessionStorage().getBaseHost()}/auth/graphql`,
    }),
  });
};

export default buildAuthClient;
