import {
  Observable,
  ApolloLink,
  FetchResult,
  NextLink,
  Operation,
} from "@apollo/client";

import { getGlobalSessionStorage } from "../session-provider/session-storage";

export class AuthLink extends ApolloLink {
  private forceToken: string | null;
  private checkHasAuthenticatedSuccessfully: boolean | undefined;

  constructor(
    options: {
      checkHasAuthenticatedSuccessfully?: boolean;
      forceToken?: string | null;
    } = {
      checkHasAuthenticatedSuccessfully: true,
    }
  ) {
    super();

    this.forceToken = options.forceToken || null;
    this.checkHasAuthenticatedSuccessfully =
      options.checkHasAuthenticatedSuccessfully;
  }

  public request(operation: Operation, forward: NextLink) {
    let token = this.forceToken;

    if (token === null) {
      token = getGlobalSessionStorage().token;
    }

    if (
      (this.checkHasAuthenticatedSuccessfully &&
        !getGlobalSessionStorage().hasAuthenticatedSuccessfully) ||
      !token
    ) {
      return new Observable<FetchResult>((observer) => {
        observer.complete();
      });
    }

    operation.setContext({
      headers: {
        "X-SKODEL-DEVICE-TYPE": "WEB",
        "X-SKODEL-GLOBAL-AUTH":
          this.forceToken || getGlobalSessionStorage().token,
      },
    });

    return forward(operation);
  }
}

export default AuthLink;
