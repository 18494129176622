import styled from "styled-components";

import { Text } from "../../";

export const Label = styled(Text)`
  border-radius: 0.325em;
  display: inline-flex;
  padding: 4px 6px;

  text-align: center;
  font-size: 0.8em;

  background: rgb(220, 235, 254);
  color: rgb(31, 53, 87);
`;

export default Label;
