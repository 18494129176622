import styled from "styled-components";

import { Box } from "../../core";

export const PanelBase = styled(Box)`
  border-radius: 12px;
  background: #ffffff;
`;

export default PanelBase;
