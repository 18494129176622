import { DocumentNode } from "graphql";
import React from "react";

interface SelectQueryContextValue {
  GetAccountsByName?: DocumentNode;
  GetCheckInStylesByName?: DocumentNode;
  GetCountriesByName?: DocumentNode;
  GetFacilitatorsByName?: DocumentNode;
  GetGroupsByName?: DocumentNode;
  GetLanguagesByName?: DocumentNode;
  GetOrganizationsByName?: DocumentNode;
  GetRespondentsByName?: DocumentNode;
  GetRespondentsGroupsSearch?: DocumentNode;
}

type SelectQueryName = keyof SelectQueryContextValue;

const initialValue: SelectQueryContextValue = {};

export const SelectQueryContext = React.createContext(initialValue);

export const useFormQueryFromContext: (
  queryName: SelectQueryName
) => DocumentNode = (queryName) => {
  const ctx = React.useContext(SelectQueryContext);
  const queryValue = ctx[queryName];

  if (!queryValue) {
    throw new Error(
      `Could not initialize ${queryName}, GraphQL query not setup in SelectQueryContext`
    );
  }

  return queryValue;
};