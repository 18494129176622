export const processIntrospectionQueryResultData = (resultData: any) => {
  const possibleTypes: any = {};

  resultData["__schema"]["types"].forEach((supertype: any) => {
    if (supertype.possibleTypes) {
      // TODO: handle null usecase
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        (subtype: any) => subtype.name
      );
    } else {
      possibleTypes[supertype.name] = [];
    }
  });

  return possibleTypes;
};

export default processIntrospectionQueryResultData;
