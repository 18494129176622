import i18next from "i18next";
import Backend from "i18next-http-backend";
import Locize from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

export const DEFAULT_LANG = "en-AU";
export const DEFAULT_NAMESPACE = "default";

export const buildTestingi18nInstance = ({
  saveMissing = false,
  namespace,
  defaultLanguage,
  ...otherProps
}: {
  saveMissing?: boolean;
  namespace?: string;
  defaultLanguage?: string;
}) =>
  i18next
    .use(initReactI18next)
    // .use(Backend)
    .init({
      lng: defaultLanguage || DEFAULT_LANG,
      fallbackLng: defaultLanguage || DEFAULT_LANG,
      preload: [],
      ns: [namespace || DEFAULT_NAMESPACE],
      defaultNS: namespace || DEFAULT_NAMESPACE,
      saveMissing,
      debug: true,
      resources: {
        [defaultLanguage || DEFAULT_LANG]: {
          [namespace || DEFAULT_NAMESPACE]: {},
        },
      },
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      ...otherProps,
    });

export const buildProductioni18nInstance = ({
  saveMissing = false,
  namespace,
  defaultLanguage,
  ...otherProps
}: {
  saveMissing?: boolean;
  namespace?: string;
  defaultLanguage?: string;
}) =>
  i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
      lng:
        localStorage.getItem("i18nextLng") || defaultLanguage || DEFAULT_LANG,
      fallbackLng: defaultLanguage || DEFAULT_LANG,
      preload: [defaultLanguage || DEFAULT_LANG], // should we set localStorage.getItem('i18nextLng') here
      ns: [namespace || DEFAULT_NAMESPACE],
      defaultNS: namespace || DEFAULT_NAMESPACE,
      // keySeparator: false,
      saveMissing,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      ...otherProps,
    });

export const buildDevelopmenti18nInstance = ({
  saveMissing = true,
  namespace,
  defaultLanguage,
  projectId,
  apiKey,
}: {
  saveMissing?: boolean;
  projectId: string;
  namespace?: string;
  defaultLanguage?: string;
  apiKey: string;
}) =>
  // @ts-ignore
  i18next
    .use(initReactI18next)
    .use(Locize)
    .init({
      backend: {
        projectId: projectId,
        apiKey: apiKey,
        referenceLng: defaultLanguage || DEFAULT_LANG,
        private: false,
        version: "latest",
        allowedAddOrUpdateHosts: ["localhost:3000", "localhost"],
      },
      lng:
        localStorage.getItem("i18nextLng") || defaultLanguage || DEFAULT_LANG,
      fallbackLng: defaultLanguage || DEFAULT_LANG,
      preload: [defaultLanguage || DEFAULT_LANG],
      ns: [namespace || DEFAULT_NAMESPACE],
      defaultNS: namespace || DEFAULT_NAMESPACE,
      // keySeparator: false, // we do not use keys in form messages.welcome
      saveMissing,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
