import styled from "styled-components";

/**
 * A visually hidden input which can be used in custom form controls to hold a
 * screenreader accessible value
 */
const HiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export default HiddenInput;
