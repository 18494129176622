import { Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { getGlobalSessionStorage } from "../session-provider/session-storage";

export const buildAuthenticationErrorLink = () =>
  onError(({ networkError = {}, operation, forward }) => {
    if (networkError && networkError.name === "ServerError") {
      const json = (networkError as any).result;

      if (
        json.error &&
        (json.error.toLowerCase() === "not authenticated" ||
          json.error.toLowerCase() === "invalid authentication token")
      ) {
        return new Observable((subscriber: any) => {
          // if(typeof localStorage.getItem('skodel-refresh-token') === "string" && typeof localStorage.getItem('skodel-refresh-token-time') === "string") {
          //     const refreshToken = localStorage.getItem('skodel-refresh-token');
          //     // @ts-ignore
          //     const refreshTokenTime = parseInt(localStorage.getItem('skodel-refresh-token-time'));
          //     const currentTime = Math.floor(new Date().getTime() / 1000);
          //     console.log(currentTime, refreshTokenTime, currentTime - refreshTokenTime);

          getGlobalSessionStorage().onTokenError();
          subscriber.error(true);
        }); //.flatMap(() => forward(operation));
      }
    }
    return forward(operation);
  });

export default buildAuthenticationErrorLink;
