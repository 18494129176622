import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { Flex } from "../../core";

export const InlineLoader = () => (
  <Flex p={3} alignItems="center" justifyContent="center">
    <ClipLoader />
  </Flex>
);

export default InlineLoader;
