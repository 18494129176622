import { DocumentNode } from "graphql";
import React from "react";

const initialValue: {
  GetCheckInCollectionName?: DocumentNode;
  GetCheckInStyleName?: DocumentNode;
  GetGroupName?: DocumentNode;
  GetOrganizationName?: DocumentNode;
  GetScheduledCheckInName?: DocumentNode;
  GetUserName?: DocumentNode;
} = {};

export const NameQueryContext = React.createContext(initialValue);

export const useNameQueryFromContext: (queryName: string) => DocumentNode = (
  queryName
) => {
  const ctx = React.useContext(NameQueryContext);
  // @ts-ignore
  const queryValue = ctx[queryName as any];

  if (!queryValue) {
    throw new Error(
      `Could not initialize ${queryName}, GraphQL query not setup in NameQueryContext`
    );
  }

  return queryValue;
};
