import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { Flex } from "../../core";

export const PageLoader = () => (
  <Flex
    p={3}
    alignItems="center"
    justifyContent="center"
    style={{
      minWidth: "100vw",
      minHeight: "70vh",
    }}
  >
    <ClipLoader />
  </Flex>
);

export default PageLoader;
