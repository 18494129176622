import { useQuery } from "@apollo/client";
import React from "react";

import { useBaseQueryFromContext } from "../../base/query-context";
import BrandLogo from "./logo.svg";

export const LogoImage = ({ style, ...otherProps }: any) => {
  return (
    <img
      src={BrandLogo}
      alt="Skodel"
      style={{
        maxHeight: "28px",
        ...style,
      }}
      {...otherProps}
    />
  );
};

export const Logo = (otherProps: any) => {
  const GetBrandLogo = useBaseQueryFromContext("GetBrandLogo");

  const { data, loading, error } = useQuery(GetBrandLogo, {
    fetchPolicy: "cache-first",
  });

  const shouldShowCustomLogo =
    !error && !loading && data.viewer.organization.hasBrandLogo;
  const logoSrc = shouldShowCustomLogo
    ? data.viewer.organization.hasBrandLogo
    : BrandLogo;

  return <LogoImage src={logoSrc} {...otherProps} />;
};

export default Logo;
