import { format, startOfWeek, subDays, subWeeks } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import styled, { css } from "styled-components";

export interface DateFilter {
  endDate: string;
  startDate: string;
}

const COLOR_SCALE_NEUTRAL = "#EEE" as const;
const COLOR_SCALE_BRAND_NEUTRAL = "#F0E3FF";
const COLOR_SCALE_BRAND_ACTIVE = "#4F1A8F";
const COLOR_SCALE_POSITIVE_ACTIVE = "#2B9973";
const COLOR_SCALE_NEGATIVE_ACTIVE = "#BF5F61";
const COLOR_SCALE_BRAND = ["#D0A9FF", "#A15EF0", "#6922BE"] as const;
const COLOR_SCALE_NEGATIVE = [
  "#f3e1e0",
  "#f7d3d2",
  "#fac6c4",
  "#fdb8b6",
  "#feaba8",
  "#ff9c9b",
  "#ff8e8e",
  "#ff7f81",
] as const;
const COLOR_SCALE_POSITIVE = [
  "#dbeae3",
  "#c8e7d8",
  "#b5e3cd",
  "#a1dec3",
  "#8cdab8",
  "#75d6ae",
  "#5bd1a3",
  "#39cc99",
] as const;

export const StyledCalendarHeatmap = styled.div`
  .react-calendar-heatmap {
    overflow: visible;

    text {
      font-size: 7px;
      fill: #8a92a6;
    }

    .color-scale-brand-active {
      stroke: ${COLOR_SCALE_BRAND_ACTIVE};
    }

    .color-scale-pos-active {
      stroke: ${COLOR_SCALE_POSITIVE_ACTIVE};
    }

    .color-scale-neg-active {
      stroke: ${COLOR_SCALE_NEGATIVE_ACTIVE};
    }

    .color-scale-neutral {
      fill: ${COLOR_SCALE_NEUTRAL};
    }

    .color-scale-brand-neutral {
      fill: ${COLOR_SCALE_BRAND_NEUTRAL};
    }

    ${() =>
      COLOR_SCALE_BRAND.map(
        (color, index) => css`
          .color-scale-${index + 1} {
            fill: ${color};
          }
        `
      )}

    ${() =>
      COLOR_SCALE_NEGATIVE.map(
        (color, index) => css`
          .color-scale-neg-${index + 1} {
            fill: ${color};
          }
        `
      )}

    ${() =>
      COLOR_SCALE_POSITIVE.map(
        (color, index) => css`
          .color-scale-pos-${index + 1} {
            fill: ${color};
          }
        `
      )}
  }
`;

const ColorScale = {
  BRAND_ACTIVE: "color-scale-brand-active",
  BRAND_NEUTRAL: "color-scale-brand-neutral",
  BRAND_ONE: "color-scale-1",
  BRAND_TWO: "color-scale-2",
  BRAND_THREE: "color-scale-3",
  NEGATIVE_ACTIVE: "color-scale-neg-active",
  NEGATIVE_ONE: "color-scale-neg-1",
  NEGATIVE_TWO: "color-scale-neg-2",
  NEGATIVE_THREE: "color-scale-neg-3",
  NEGATIVE_FOUR: "color-scale-neg-4",
  NEGATIVE_FIVE: "color-scale-neg-5",
  NEGATIVE_SIX: "color-scale-neg-6",
  NEGATIVE_SEVEN: "color-scale-neg-7",
  NEGATIVE_EIGHT: "color-scale-neg-8",
  NEUTRAL: "color-scale-neutral",
  POSITIVE_ACTIVE: "color-scale-pos-active",
  POSITIVE_ONE: "color-scale-pos-1",
  POSITIVE_TWO: "color-scale-pos-2",
  POSITIVE_THREE: "color-scale-pos-3",
  POSITIVE_FOUR: "color-scale-pos-4",
  POSITIVE_FIVE: "color-scale-pos-5",
  POSITIVE_SIX: "color-scale-pos-6",
  POSITIVE_SEVEN: "color-scale-pos-7",
  POSITIVE_EIGHT: "color-scale-pos-8",
} as const;

export type ColorScale = typeof ColorScale;
export type ColorScaleValue = ColorScale[keyof ColorScale];

export interface CalendarChartValue {
  active?: boolean;
  count: number;
  date: string;
}

export type ScaleCalculator = (
  value: CalendarChartValue | undefined,
  scale: ColorScale
) => ColorScaleValue | ColorScaleValue[];

export interface GetWeeksDateFilterOptions {
  weeks?: number;
  formatString?: string;
}

export const getCalendarChartDateFilter = ({
  weeks = 26,
  formatString,
}: GetWeeksDateFilterOptions = {}): DateFilter => {
  const endDate = new Date();
  const startDate = subDays(subWeeks(startOfWeek(endDate), weeks), 1);

  return formatString
    ? {
        endDate: format(endDate, formatString),
        startDate: format(startDate, formatString),
      }
    : {
        endDate: endDate.toJSON(),
        startDate: startDate.toJSON(),
      };
};

export const getCalendarChartStartEndDates = (weeks?: number): DateFilter => {
  return getCalendarChartDateFilter({
    formatString: "yyyy-MM-dd",
    weeks,
  });
};

export type TitleFormatter = (value?: CalendarChartValue) => string | undefined;

interface CalendarChartProps {
  calculateScale: ScaleCalculator;
  values: CalendarChartValue[];
  titleForValue?: TitleFormatter;
  weeks?: number;
}

const CalendarChart = ({
  calculateScale,
  values,
  titleForValue,
  weeks,
}: CalendarChartProps) => {
  const [{ endDate, startDate }, setStartEndDates] = useState(() =>
    getCalendarChartStartEndDates(weeks)
  );
  useEffect(() => {
    setStartEndDates(getCalendarChartStartEndDates(weeks));
  }, [weeks]);

  const classForValue = useCallback(
    (value?: CalendarChartValue) => {
      const colorScaleValue = calculateScale(value, ColorScale);
      if (typeof colorScaleValue === "string") return colorScaleValue;
      return colorScaleValue.join(" ");
    },
    [calculateScale]
  );

  return (
    <StyledCalendarHeatmap>
      <CalendarHeatmap
        classForValue={classForValue}
        endDate={endDate}
        gutterSize={4}
        startDate={startDate}
        titleForValue={titleForValue}
        transformDayElement={(element) =>
          React.cloneElement(element, { rx: "2" })
        }
        values={values}
      />
    </StyledCalendarHeatmap>
  );
};

export default CalendarChart;
