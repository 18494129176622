import styled from "styled-components";

import { Box } from "../../core";

export const CardGridContainer = styled(Box)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
`;

export default CardGridContainer;
