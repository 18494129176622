import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import { StyledIcon } from "@styled-icons/styled-icon";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { Box } from "../../core";
import styledButtonReset from "../../helpers/styledButtonReset";
import { Panel } from "../panel";

const sidebarItemStyles = css`
  padding: 8px 16px;
  color: #9842ff;
  font-family: "Nunito Sans Bold";
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
`;

const navLinkStateStyles = css`
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  &.active {
    background-color: #9842ff;
    color: #fff;

    :hover {
      text-decoration: none;
    }
  }
`;

const SidebarGroupToggleButton = styled.button`
  ${styledButtonReset}
  ${sidebarItemStyles}
  display: flex;
  width: 100%;
  padding-right: 0;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarPrimaryNavLink = styled(NavLink)`
  ${sidebarItemStyles}
  ${navLinkStateStyles}
`;

export const SidebarNavLink = styled(NavLink)`
  ${sidebarItemStyles}
  ${navLinkStateStyles}
  display: block;
  color: #232d42;
`;

type SidebarItemGroupProps = {
  children?: React.ReactNode;
  icon?: StyledIcon;
  defaultIsOpen?: boolean;
  label: string;
};

export const SidebarItemGroup = ({
  children,
  defaultIsOpen = true,
  label,
}: SidebarItemGroupProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultIsOpen);
  const ToggleIcon = isOpen ? ChevronUp : ChevronDown;

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <SidebarGroupToggleButton onClick={onToggleClick} type="button">
        {label}
        <ToggleIcon color="#A174FF" width="24px" />
      </SidebarGroupToggleButton>
      {children && isOpen && (
        <Box pl="16px" pb="8px">
          {children}
        </Box>
      )}
    </Box>
  );
};

interface SidebarProps {
  children?: React.ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  return (
    <Panel.Base
      px="8px"
      py="24px"
      sx={{
        display: "grid",
        gap: "8px",
      }}
    >
      {children}
    </Panel.Base>
  );
};

export default Sidebar;
