import { toast } from "react-toastify";

export const setupToast = () => {
  toast.configure({
    autoClose: 8000,
    draggable: false,
  });
};

export default setupToast;
