import { Input } from "@rebass/forms";
import styled from "styled-components";

export const TextInput = styled(Input)`
  border: 1px solid #cccccc !important;
  background: #ffffff;
  border-radius: 4px;
  font: inherit;
  transition: all 100ms;
  padding: 8px;

  &:hover {
    border: 1px solid ${(props: any) => (props.isInvalid ? "red" : "#b3b3b3")} !important;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px
      ${(props: any) => (props.isInvalid ? "red" : "#2684ff")};
    outline: 0 !important;
    border-color: ${(props: any) =>
      props.isInvalid ? "red" : "#2684ff"} !important;
  }

  &:disabled {
    background: #eaeaea;
    cursor: not-allowed;
  }
`;

TextInput.defaultProps = {
  type: "text",
  as: "input",
};

export default TextInput;
