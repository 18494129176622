import React from "react";
import styled from "styled-components";

import HiddenInput from "../hidden-input";
import InputGroup, { InputGroupProps, useInputGroup } from "../input-group";

const StyledToggleButtonGroup = styled.div`
  display: inline-flex;
  border-radius: 8px;
`;

type ToggleButtonGroupProps = InputGroupProps & {
  children?: React.ReactNode;
};

const ToggleButtonGroup = ({ children, ...props }: ToggleButtonGroupProps) => {
  return (
    <InputGroup {...props}>
      <StyledToggleButtonGroup>{children}</StyledToggleButtonGroup>
    </InputGroup>
  );
};

const StyledToggleButton = styled.div`
  color: rgb(152, 66, 255);
  background-color: #fff;
  padding: 4px 8px;
  cursor: pointer;

  ${HiddenInput}:focus + &, :hover {
    background-color: rgba(152, 66, 255, 0.1);
  }

  ${HiddenInput}:checked + & {
    background-color: rgb(152, 66, 255);
    color: #fff;
  }
`;

const StyledToggleButtonLabel = styled.label`
  display: inline-flex;
  border: 1px solid rgb(152, 66, 255);
  border-radius: 8px;
  overflow: clip;

  :not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :not(:first-of-type) {
    margin-left: -1px;
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

interface ToggleButtonProps {
  children?: React.ReactNode;
  value: string;
}

const ToggleButton = ({ children, value: ownValue }: ToggleButtonProps) => {
  const {
    name,
    defaultValue,
    onChange,
    type,
    value: groupValue,
  } = useInputGroup();

  return (
    <StyledToggleButtonLabel>
      <HiddenInput
        checked={
          type === "checkbox"
            ? groupValue.includes(ownValue)
            : groupValue === ownValue
        }
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        type={type}
        value={ownValue}
      />
      <StyledToggleButton>{children}</StyledToggleButton>
    </StyledToggleButtonLabel>
  );
};

export default Object.assign(
  {},
  {
    Button: ToggleButton,
    Group: ToggleButtonGroup,
  }
);
