import queryString from "querystring";

export const getFeedParams: (
  location: any,
  defaultCount?: number
) => {
  page: number;
  count?: number;
} = (location, defaultCount) => {
  const query: {
    page?: string;
    count?: string;
  } = queryString.parse(location.search);

  const output: {
    page: number;
    count?: number;
  } = {
    page: 1,
  };

  if (defaultCount) {
    output.count = defaultCount;
  }

  if (query.page) {
    output.page = parseInt(query.page);
    // @ts-ignore
  } else if (query["?page"]) {
    // @ts-ignore
    output.page = parseInt(query["?page"]);
  }

  if (query.count) {
    output.count = parseInt(query.count);
    // @ts-ignore
  } else if (query["?count"]) {
    // @ts-ignore
    output.count = parseInt(query["?count"]);
  }

  return output;
};

export const getNewLocation = (
  feedParams: {
    page: number;
  },
  location: any
) => {
  return {
    pathname: location.pathname,
    search: queryString.stringify({
      page: feedParams.page,
    }),
  };
};

export const getPageNumbers = ({
  currentPage,
  totalPages,
  maxPagesOnScreen = DEFAULT_MAX_PAGES_ON_SCREEN,
}: {
  currentPage: number;
  totalPages: number;
  maxPagesOnScreen?: number; // doesn't include active page
}) => {
  const halfMaxPages = Math.floor(maxPagesOnScreen / 2);
  const pageNumbers = [];

  let start = Math.max(currentPage - halfMaxPages, 1);
  let end = Math.min(currentPage + halfMaxPages, totalPages);
  let pageCount = end - start;

  if (maxPagesOnScreen > pageCount && totalPages > maxPagesOnScreen) {
    if (start === 1) {
      end = Math.max(maxPagesOnScreen + 1, maxPagesOnScreen - end);
    } else if (end === totalPages) {
      start = Math.max(1, end - maxPagesOnScreen);
    }
  }

  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  return pageNumbers;
};

export const pushWithHook = (scrollToTop: boolean = false, ...args: any) => {
  if (scrollToTop) {
    window.scrollTo(0, 0);
  }
  args[0].push(args[1]);
};

export const DEFAULT_MAX_PAGES_ON_SCREEN = 4;
