import { FirstPage } from "@styled-icons/boxicons-regular/FirstPage";
import { LastPage } from "@styled-icons/boxicons-regular/LastPage";
import React from "react";
import { useHistory, useLocation } from "react-router";

import { Flex, Button } from "../../core";
import { getPageNumbers, pushWithHook } from "./utils";

export const Pagination: React.FC<{
  pageInfo: {
    currentPage: number;
    totalPages: number;
  };
  disabled?: boolean;
  feedParams: any;
  scrollToTop?: boolean;
  getNewLocation: (
    feedParams: {
      page: number;
    },
    location: any
  ) => any;
}> = ({
  pageInfo,
  feedParams,
  getNewLocation,
  scrollToTop = true,
  disabled = false,
}: any) => {
  const location = useLocation();
  const history = useHistory();

  const currentPage = pageInfo.currentPage;
  const totalPages = pageInfo.totalPages;

  const pageNumbers = getPageNumbers({
    currentPage,
    totalPages,
  });

  if (pageNumbers.length < 2) {
    return null;
  }

  const goToPage = (number: number) => {
    pushWithHook(
      scrollToTop,
      history,
      getNewLocation(
        {
          ...feedParams,
          page: number,
        },
        location
      )
    );
  };

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      style={{
        gap: "8px",
      }}
    >
      {currentPage !== 1 && (
        <Button
          variant="link"
          padding="8px 12px"
          onClick={(_) => goToPage(1)}
          disabled={disabled}
        >
          <FirstPage width="1em" height="1em" />
        </Button>
      )}
      {pageNumbers.map((number) => {
        if (number !== currentPage) {
          return (
            <Button
              variant="link"
              padding="8px 12px"
              key={number}
              onClick={(_) => goToPage(number)}
              disabled={disabled}
            >
              {number.toString()}
            </Button>
          );
        }
        return (
          <Button
            variant="brand"
            padding="8px 12px"
            key={number}
            disabled={disabled}
          >
            {number.toString()}
          </Button>
        );
      })}
      {currentPage < totalPages && (
        <Button
          variant="link"
          padding="8px 12px"
          onClick={(_) => goToPage(totalPages)}
          disabled={disabled}
        >
          <LastPage width="1em" height="1em" />
        </Button>
      )}
    </Flex>
  );
};

export default Pagination;
