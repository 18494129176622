import { Close } from "@styled-icons/remix-line/Close";
import React from "react";

import { Button } from "../../core";

export const ModalCloseButton = ({ onClose, children, ...otherProps }: any) => (
  <Button
    variant="linkInverse"
    padding="4px"
    onClick={() => onClose()}
    {...otherProps}
  >
    <Close width="20px" height="20px" />
    {children}
  </Button>
);

export default ModalCloseButton;
