import { useMutation, useQuery } from "@apollo/client";
import React from "react";

import { useBaseQueryFromContext } from "../../base/query-context";
import { Flex, Text } from "../../core";

export const ImpersonationCheck = () => {
  const GetUserAccountImpersonation = useBaseQueryFromContext(
    "GetUserAccountImpersonation"
  );
  const StopImpersonating = useBaseQueryFromContext("StopImpersonating");

  const [stopImpersonating, stopImpersonatingState] = useMutation(
    StopImpersonating,
    {
      refetchQueries: ["GetUserAccountImpersonation"],
    }
  );

  const { data, error } = useQuery(GetUserAccountImpersonation);

  if (error || !data || data.unimpersonatedViewer.id === data.viewer.id) {
    return null;
  }

  return (
    <Flex
      bg="red"
      p="3"
      mb="4"
      color="#ffffff"
      style={{
        borderRadius: "6px",
      }}
    >
      <Text>
        You are currently impersonating {data.viewer.firstName}{" "}
        {data.viewer.lastName}. Remember, all actions are logged with this users
        account as well as your original account (
        {data.unimpersonatedViewer.firstName}{" "}
        {data.unimpersonatedViewer.lastName}).{" "}
        {!stopImpersonatingState.loading && (
          <Text
            style={{
              display: "inline-block",
            }}
          >
            Click{" "}
            <a
              href="/"
              onClick={(e: any) => {
                stopImpersonating();
                e.preventDefault();
              }}
              style={{
                color: "#FFFFFF",
              }}
            >
              here
            </a>{" "}
            to stop impersonating this user.
          </Text>
        )}
      </Text>
    </Flex>
  );
};

export default ImpersonationCheck;
