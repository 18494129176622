import { useQuery, useMutation } from "@apollo/client";
import { ArrowDropDown } from "@styled-icons/remix-line/ArrowDropDown";
import { debounce } from "lodash";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

import { RelativeTimeText } from "../../..";
import { BlankAvatar, LazyAvatar } from "../../../brand/avatar";
import { ReactionIcon } from "../../../brand/icons/ReactionIcon";
import { Flex, Text, Button } from "../../../core";
import { TextInput } from "../../../forms/text-input";
import { InlineSpinner } from "../../../loaders/inline-spinner";
import { UserName } from "../../../name-helpers/user/";
import AnonymousUserName from "../AnonymousUserName";
import { useCheckInQueryFromContext } from "../query-context";
import CommentUserName from "./CommentUserName";
import { ReactionRenderer } from "./hover";

const offsets = [0, 10, 16];

const ReactionWrapper = styled(Flex)`
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(170, 99, 255, 0.3);
  border-radius: 0.35em;
  right: 5px;
  bottom: -15px;
  position: absolute;
  background: #ffffff;
  transition: all 0.1s ease-in;
  max-width: 250px;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba(170, 99, 255, 0.5);
    bottom: -16px;
  }
`;

export const CommentReactionText = ({
  reactionUserIds,
}: {
  reactionUserIds: string[];
}) => {
  const { t } = useTranslation();

  const users = reactionUserIds
    .slice(0, 3)
    .map((userId: any) =>
      userId === null ? <AnonymousUserName /> : <UserName userId={userId} />
    );

  if (reactionUserIds.length === 1) {
    return (
      <>
        <Trans t={t} i18nKey="CheckIn.comment_reaction_text_1">
          {users[0]}
        </Trans>
      </>
    );
  } else if (reactionUserIds.length === 2) {
    return (
      <>
        <Trans t={t} i18nKey="CheckIn.comment_reaction_text_2">
          {users[0]} and {users[1]}
        </Trans>
      </>
    );
  } else if (reactionUserIds.length === 3) {
    return (
      <>
        <Trans t={t} i18nKey="CheckIn.comment_reaction_text_3">
          {users[0]}, {users[1]} and {users[2]}
        </Trans>
      </>
    );
  } else if (reactionUserIds.length === 4) {
    return (
      <>
        <Trans t={t} i18nKey="CheckIn.comment_reaction_text_4">
          {users[0]}, {users[1]}, {users[2]} and 1 other
        </Trans>
      </>
    );
  } else if (reactionUserIds.length >= 5) {
    return (
      <>
        <Trans t={t} i18nKey="CheckIn.comment_reaction_text_5">
          {users[0]}, {users[1]}, {users[2]} and{" "}
          {{ reactionsMinus3: users.length - 3 }} others
        </Trans>
      </>
    );
  } else {
    return null;
  }
};

export const ReactionTypeStack = ({
  reactionTypes,
  ...otherProps
}: {
  reactionTypes: string[];
  style?: any;
}) => {
  const maxCount = Math.min(reactionTypes.length, 3);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{
        position: "relative",
        width: maxCount === 1 ? "20px" : maxCount === 2 ? "28px" : "35px",
        ...otherProps.style,
      }}
    >
      {reactionTypes.splice(0, 3).map((reactionType, idx) => (
        <ReactionIcon
          key={idx}
          reactionType={reactionType}
          style={{
            height: "auto",
            width: "20px",
            left: `${offsets[idx]}px`,
            position: "absolute",
            zIndex: 4 - idx,
          }}
        />
      ))}
    </Flex>
  );
};

const HoverText = styled(Text)`
  cursor: pointer;
  overflow: hidden;

  & > :first-child {
    transition: all ease-in 0.5s;
    transition: opacity ease-in 0.2s;

    visibility: visible;
    opacity: 1;
    height: initial;
    width: initial;
  }

  & > :last-child {
    transition: all ease-in 0.5s;
    transition: opacity ease-in 0.2s;

    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &:hover {
    & > :first-child {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
    }
    & > :last-child {
      visibility: visible;
      opacity: 1;
      height: initial;
      width: initial;
    }
  }
`;

export const TopReactions = ({
  reactionTypes,
  reactionCount,
  reactionUserIds,
}: {
  reactionTypes: string[];
  reactionUserIds: string[];
  reactionCount: number;
}) => {
  const maxCount = Math.min(reactionTypes.length, 3);

  return (
    <ReactionWrapper p={1}>
      <Flex
        style={{
          minWidth: maxCount === 1 ? "20px" : maxCount === 2 ? "28px" : "35px",
        }}
      >
        <ReactionTypeStack reactionTypes={reactionTypes} />
      </Flex>
      <HoverText fontSize="0.8em" pl="1">
        <Flex as="span">{reactionCount}</Flex>
        <Flex as="span">
          <CommentReactionText reactionUserIds={reactionUserIds} />
        </Flex>
      </HoverText>
    </ReactionWrapper>
  );
};

const CommentReactionButton = ({
  areInteractionsAnonymous,
  commentId,
  myReactions,
}: {
  areInteractionsAnonymous: boolean;
  commentId: string;
  myReactions: {
    node: {
      id: string;
      reactionType: string;
    };
  }[];
}) => {
  const { t } = useTranslation();
  const ReactToComment = useCheckInQueryFromContext("ReactToComment");
  const [reactToComment, reactToCommentState] = useMutation(ReactToComment);

  const UpdateReaction = useCheckInQueryFromContext("UpdateReaction");
  const [updateReaction, updateReactionState] = useMutation(UpdateReaction);

  const [areReactionsOpen, setAreReactionsOpen] = useState(false);

  const reaction = myReactions.length === 0 ? null : myReactions[0];

  const loading = updateReactionState.loading || reactToCommentState.loading;

  return (
    <Button
      variant="linkSmall"
      disabled={loading}
      onClick={async () => {
        setAreReactionsOpen(true);
      }}
      style={{
        fontSize: "0.8em",
        fontFamily: "Nunito Sans Bold",
      }}
    >
      {areReactionsOpen && (
        <Flex
          style={{
            position: "relative",
          }}
        >
          <ReactionRenderer
            reaction={reaction}
            reactToItem={async (
              reactionId: string,
              update: boolean = false
            ) => {
              let data = null;
              try {
                if (!update) {
                  const { data: queryData } = await reactToComment({
                    variables: {
                      commentId: commentId,
                      reactionType: reactionId,
                    },
                  });

                  data = queryData;
                } else {
                  const { data: queryData } = await updateReaction({
                    variables: {
                      // @ts-ignore
                      reactionId: reaction.node.id,
                      reactionType: reactionId,
                    },
                  });

                  data = queryData;
                }

                const result = data?.reactToComment || data?.updateReaction;
                if (!result.success) {
                  console.error(
                    "Received the following errors while trying to react to a comment",
                    result
                  );
                  toast.error(
                    t("CommentReactionButton.error_reacting_to_comment", {
                      defaultValue:
                        "An unexpected error occurred while trying to react to this comment. Please check your internet connection and try again.",
                    })
                  );
                }
              } catch (e) {
                console.error(
                  "Received the following errors while trying to react to a comment",
                  e
                );
                toast.error(
                  t("CommentReactionButton.error_reacting_to_comment", {
                    defaultValue:
                      "An unexpected error occurred while trying to react to this comment. Please check your internet connection and try again.",
                  })
                );
              }
              setAreReactionsOpen(false);
            }}
            setAreReactionsOpen={setAreReactionsOpen}
            style={{
              top: "-60px",
              left: "-12px",
            }}
          />
        </Flex>
      )}
      {loading ? (
        <Flex flexDirection="row" alignItems="center" mt={1}>
          <Flex mr={2}>
            <InlineSpinner width="12px" height="12px" />
          </Flex>
          <Text>
            {t("CheckIn.react_to_this_comment_loading", {
              defaultValue: "Loading",
            })}
          </Text>
        </Flex>
      ) : (
        <>
          <Flex flexDirection="row" alignItems="center" mt={1}>
            {reaction && (
              <Flex mr={2}>
                <ReactionIcon
                  reactionType={reaction.node.reactionType}
                  style={{
                    height: "16px",
                    width: "auto",
                  }}
                />
              </Flex>
            )}
            <Text>
              {areInteractionsAnonymous
                ? t("CheckIn.anonymous_react_to_comment", {
                    defaultValue: "React anonymously",
                  })
                : t("CheckIn.react_to_comment", {
                    defaultValue: "React",
                  })}
            </Text>
          </Flex>
        </>
      )}
    </Button>
  );
};

const CommentBar = ({
  areInteractionsAnonymous,
  onContent,
}: {
  areInteractionsAnonymous: boolean;
  onContent: (content: string) => Promise<any>;
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [isDebounced, setIsDebounced] = useState(false);

  const handler = useCallback(
    debounce(() => setIsDebounced(false), 2000),
    [isDebounced]
  );

  return (
    <Flex alignItems="stretch" flex="1">
      <TextInput
        id="content"
        name="content"
        type="content"
        px={2}
        placeholder={
          areInteractionsAnonymous
            ? t("CheckIn.anonymous_comment_placeholder", {
                defaultValue: "Leave an anonymous comment",
              })
            : t("CheckIn.comment_placeholder", {
                defaultValue: "Leave a comment",
              })
        }
        onKeyDown={async (event) => {
          if (event.key === "Enter" && content.length !== 0 && !isDebounced) {
            setIsDebounced(true);
            handler();

            await onContent(content);
            setContent("");
          }
        }}
        onChange={async (e: any) => setContent(e.target.value)}
        value={content}
        style={{
          border: "1px solid #8A92A6",
          font: "inherit",
          borderRadius: "5px",
          flex: "1",
        }}
      />
      <Button
        variant="link"
        ml={2}
        disabled={content.length === 0 || isDebounced}
        onClick={async () => {
          setIsDebounced(true);
          handler();

          await onContent(content);
          setContent("");
        }}
        style={{
          borderRadius: 0,
          paddingLeft: "12px",
          paddingRight: "12px",
          minHeight: "100%",
        }}
      >
        {t("CheckIn.post_button", { defaultValue: "Post" })}
      </Button>
    </Flex>
  );
};

export const CheckInComments = ({
  areInteractionsAnonymous,
  checkInId,
  linkToCheckInForMoreComments = true,
  canComment = true,
  linkToUsers = true,
}: {
  areInteractionsAnonymous: boolean;
  checkInId: string;
  linkToCheckInForMoreComments?: boolean;
  canComment?: boolean;
  linkToUsers?: boolean;
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const history = useHistory();

  const GetViewerIdForComments = useCheckInQueryFromContext(
    "GetViewerIdForComments"
  );

  const viewerQuery = useQuery(GetViewerIdForComments, {
    fetchPolicy: "cache-and-network",
  });

  const GetCommentsForCheckIn = useCheckInQueryFromContext(
    "GetCommentsForCheckIn"
  );

  const { loading, data, error, fetchMore } = useQuery(GetCommentsForCheckIn, {
    variables: {
      checkInId,
      count: linkToCheckInForMoreComments ? 4 : null,
      page,
    },
  });

  const DeleteCommentFromCheckIn = useCheckInQueryFromContext(
    "DeleteCommentFromCheckIn"
  );

  const [deleteCommentFromCheckIn] = useMutation(DeleteCommentFromCheckIn, {
    refetchQueries: ["GetCommentsForCheckIn"],
  });

  const CommentOnCheckIn = useCheckInQueryFromContext("CommentOnCheckIn");

  const [commentOnCheckIn] = useMutation(CommentOnCheckIn, {
    refetchQueries: ["GetCommentsForCheckIn"],
  });

  if (error || viewerQuery.error) {
    return null;
  }

  if (!viewerQuery.data || !viewerQuery.data.viewer) {
    return null;
  }

  const viewerId = viewerQuery.data.viewer.id;

  if (!data || !data.checkIn) {
    return null;
  }

  if (data.checkIn.comments.totalCount === 0) {
    if (!canComment) {
      return null;
    }

    return (
      <Flex
        px={2}
        pt={1}
        alignItems="stretch"
        flex="1"
        style={{
          transition: "0.12s ease-in",
        }}
      >
        <Flex
          mx={1}
          mt={2}
          flex="1"
          alignItems="stretch"
          flexDirection="column"
        >
          <Text fontSize={"0.8em"} color="grey" mb={3}>
            {t("CheckIn.no_comment_placeholder", {
              defaultValue: "No-one has commented yet. Be the first.",
            })}
          </Text>
          <CommentBar
            areInteractionsAnonymous={areInteractionsAnonymous}
            onContent={async (content) => {
              await commentOnCheckIn({
                variables: {
                  checkInId,
                  content,
                },
              });
            }}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" px={2} pt={2}>
      {data.checkIn.comments.edges.map(({ node }: any) => {
        const reactionUserIds = (node.reactions?.edges || []).map(
          (x: any) => x.node.createdById
        );

        return (
          <Flex
            mx={1}
            my={2}
            key={node.id}
            style={{
              transition: "5s ease-in",
              transitionProperty: "height",
            }}
            flexDirection="column"
          >
            <Flex flexDirection="row">
              {!node.createdById ? (
                <BlankAvatar
                  size={"40px"}
                  style={{
                    marginRight: "8px",
                    fontFamily: "Nunito Sans Bold",
                    fontWeight: "bold",
                  }}
                />
              ) : (
                <LazyAvatar
                  userId={node.createdById}
                  size={"40px"}
                  style={{
                    marginRight: "8px",
                    fontFamily: "Nunito Sans Bold",
                    fontWeight: "bold",
                  }}
                />
              )}
              <Flex flexDirection="column" flex="1">
                <Flex
                  bg="#EEF5F9"
                  p={2}
                  px={3}
                  flex="1"
                  style={{
                    borderRadius: "5px",
                    position: "relative",
                    // transition: "0.5s ease-in",
                  }}
                >
                  <Flex flexDirection="column" flex="1">
                    <Flex
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <Flex flex="1">
                        <CommentUserName
                          linkToUser={linkToUsers}
                          respondentId={node.createdById}
                        />
                      </Flex>
                      <Flex alignItems="flex-start" justifyContent="flex-end">
                        <Text fontSize="0.8em" color="grey" mr={2}>
                          <RelativeTimeText date={new Date(node.created)} />
                        </Text>
                        {node.createdById === viewerId && canComment && (
                          <Dropdown
                            trigger={
                              <>
                                <ArrowDropDown
                                  width="24px"
                                  height="24px"
                                  style={{
                                    marginTop: "-2px",
                                  }}
                                />
                              </>
                            }
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text={t("CheckIn.delete_comment", {
                                  defaultValue: "Delete",
                                })}
                                onClick={async () => {
                                  await deleteCommentFromCheckIn({
                                    variables: {
                                      commentId: node.id,
                                    },
                                  });
                                }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Flex>
                    </Flex>
                    <Text color="#232D42">{node.content}</Text>
                  </Flex>
                  {node?.reactionStats?.edges?.length > 0 && (
                    <TopReactions
                      reactionTypes={node.reactionStats.edges.map(
                        (reactionStat: any) => reactionStat.node.reactionType
                      )}
                      reactionCount={node.reactions.totalCount}
                      reactionUserIds={reactionUserIds}
                    />
                  )}
                </Flex>
                <Flex mt={1}>
                  <CommentReactionButton
                    areInteractionsAnonymous={areInteractionsAnonymous}
                    commentId={node.id}
                    myReactions={node.myReactions.edges}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      })}

      {data.checkIn.comments.totalCount >
        data.checkIn.comments.edges.length && (
        <Flex pt={2}>
          {linkToCheckInForMoreComments ? (
            <Button
              variant="link"
              as="a"
              style={{
                color: "#8A92A6",
                fontFamily: "Nunito Sans Bold",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "3px",
                fontSize: "0.8em",
              }}
              href={`/check-in/${data.checkIn.id}`}
              onClick={(e) => {
                e.preventDefault();

                history.push(`/check-in/${data.checkIn.id}`);

                return false;
              }}
            >
              {t("CheckIn.view_more_comments", {
                defaultValue: "View more comments",
              })}
            </Button>
          ) : (
            data.checkIn.comments.pageInfo.totalPages !== page && (
              <Button
                variant="link"
                style={{
                  color: "#8A92A6",
                  fontFamily: "Nunito Sans Bold",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  borderRadius: "3px",
                  fontSize: "0.8em",
                }}
                onClick={() => {
                  // @ts-ignore
                  fetchMore({
                    variables: {
                      page: page + 1,
                      checkInId,
                    },
                  }).then((_fetchMoreResult: any) => {
                    setPage(page + 1);
                  });
                }}
              >
                {loading && (
                  <Text mr={2}>
                    <InlineSpinner width="1em" height="1em" />
                  </Text>
                )}
                <Text>
                  {t("CheckIn.view_more_comments", {
                    defaultValue: "View more comments",
                  })}
                </Text>
              </Button>
            )
          )}
        </Flex>
      )}

      {canComment && (
        <Flex mx={1} mt={2} alignItems="stretch">
          <CommentBar
            areInteractionsAnonymous={areInteractionsAnonymous}
            onContent={async (content) => {
              await commentOnCheckIn({
                variables: {
                  checkInId,
                  content,
                },
              });
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CheckInComments;
