import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, Text } from "../../core";

const MAX_TEXT_DISPLAY_LENGTH = 400;

const TextWithFadeOut: any = styled(Text)`
  height: 100px;
  overflow: hidden;
  color: transparent; // this is essential for background-clip to work
  // the following background gradient is used to fade out the text
  background: linear-gradient(
    to bottom,
    rgb(31, 38, 56) 60%,
    #ffffff 90%,
    #ffffff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
`;

export const CheckInBodyText = ({ input }: { input?: string }) => {
  const { t } = useTranslation();
  const [shown, setShown] = useState(false);

  if (!input) {
    return null;
  }

  if (input.length < MAX_TEXT_DISPLAY_LENGTH) {
    return <Text mb="2">{input}</Text>;
  }

  if (shown) {
    return (
      <>
        <Text mb="2">{input}</Text>
        <Button variant="linkSmall" mt={1} onClick={() => setShown(false)}>
          <Trans t={t} i18nKey="CheckIn.show_less">
            Show less
          </Trans>
        </Button>
      </>
    );
  }

  // not shown
  return (
    <>
      <TextWithFadeOut mb="2">{input}</TextWithFadeOut>
      <Button variant="linkSmall" mt={2} onClick={() => setShown(true)}>
        <Trans t={t} i18nKey="CheckIn.show_more">
          Show more
        </Trans>
      </Button>
    </>
  );
};

export default CheckInBodyText;
