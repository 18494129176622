export const Theme = {
  colors: {
    text: "#232D42",
    textWeak: "#737B8B",
    bg: "#fff",
    primary: "#07c",
    secondary: "#30c",
    error: "#e74c3c",
    brand: "#a174ff",
    brandSecondary: "#9842FF",
    brandTertiary: "#AA63FF",
    brandTertiaryHover: "#A25EF2",
    brandTertiaryActive: "#9959E6",
  },
  fonts: {
    body: `'Nunito Sans SemiBold',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen", "Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
    heading: `'Nunito Sans SemiBold',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen", "Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
    monospace: "Menlo, monospace",
    button: `'Nunito Sans Bold',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen", "Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
    bold: `'Nunito Sans Bold',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen", "Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
    semibold: `'Nunito Sans SemiBold',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen", "Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
  },
  modalHeaders: {
    base: {
      background: "linear-gradient(28deg, #9f26f1 30%, rgb(162, 66, 255) 95%)",
      color: "#FFFFFF",
    },
    tour: {
      bg: "#FFFFFF",
      color: "colors.primary",
    },
  },
  buttons: {
    base: {
      transition: "0.12s ease-in",
      "&:hover:not(:disabled)": {
        cursor: "pointer",
      },
      ":disabled": {
        cursor: "not-allowed",
      },
    },
    primary: {
      variant: "buttons.base",
      fontSize: 2,
      fontWeight: "normal",
      fontFamily: "button",
      color: "#FFFFFF",
      bg: "rgba(152, 66, 255)",
      borderRadius: "default",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        bg: "rgb(132, 36, 225)",
      },
      "&:active": {
        variant: "buttons.base.&:active",
        bg: "rgb(132, 36, 225)",
      },
      ":disabled": {
        variant: "buttons.base.:disabled",
        boxShadow: "none",
        bg: "#eeeeee",
        color: "#aaaaaa",
      },
    },
    success: {
      variant: "buttons.primary",
      color: "#ffffff",
      bg: "#2ecc71",
      "&:hover:not(:disabled)": {
        variant: "buttons.primary.&:hover:not(:disabled)",
        bg: "#27ae60",
      },
      "&:active": {
        variant: "buttons.primary.&:active",
        bg: "#27ae60",
      },
    },
    danger: {
      variant: "buttons.primary",
      color: "#ffffff",
      bg: "#e74c3c",
      "&:hover:not(:disabled)": {
        variant: "buttons.primary.&:hover:not(:disabled)",
        bg: "#c0392b",
      },
      "&:active": {
        variant: "buttons.primary.&:active",
        bg: "#c0392b",
      },
    },
    warning: {
      variant: "buttons.primary",
      color: "#000000",
      bg: "#f1c40f",
      "&:hover:not(:disabled)": {
        variant: "buttons.primary.&:hover:not(:disabled)",
        bg: "#f39c12",
      },
      "&:active": {
        variant: "buttons.primary.&:active",
        bg: "#f39c12",
      },
    },
    link: {
      variant: "buttons.primary",
      bg: "transparent",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        bg: "rgba(0, 0, 0, 0.05)",
      },
      color: "rgb(132, 36, 235)",
    },
    linkInverse: {
      variant: "buttons.primary",
      bg: "rgba(0, 0, 0, 0.2)",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        bg: "rgba(0, 0, 0, 0.35)",
      },
      color: "#ffffff",
    },
    tag: {
      variant: "buttons.primary",
      border: "2px solid #d3d3d3",
      color: "#9c9c9c",
      bg: "#ffffff",
      borderRadius: "8px",
      padding: "6px 8px",
      fontSize: "0.75em",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        borderColor: "rgb(152, 66, 255)",
        color: "#ffffff",
        bg: "rgb(152, 66, 255)",
        boxShadow: "0 2px 8px 0 rgba(170, 99, 255, 0.3)",
        fontWeight: "600",
      },
    },
    tagActive: {
      variant: "buttons.tag",
      border: "2px solid rgb(152, 66, 255)",
      color: "#ffffff",
      bg: "rgb(152, 66, 255)",
      fontWeight: "600",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        borderColor: "rgb(152, 66, 255)",
        color: "#ffffff",
        bg: "rgb(152, 66, 255)",
        boxShadow: "0 2px 8px 0 rgba(170, 99, 255, 0.3)",
      },
    },
    linkSmall: {
      variant: "buttons.link",
      padding: "4px",
      borderRadius: "4px",
    },
    linkLegacy: {
      variant: "buttons.link",
      padding: "4px",
      borderRadius: "16px",
    },
    brand: {
      variant: "buttons.primary",
      bg: "rgb(152,66,255)",
      borderRadius: "16px",
      boxShadow: "0 4px 13px 0 rgb(170 99 255 / 30%)",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        bg: "rgb(132, 36, 235)",
      },
      ":disabled": {
        variant: "buttons.primary.:disabled",
        boxShadow: "none",
      },
    },
    cta: {
      variant: "buttons.primary",
      color: "#fff",
      bg: "brandTertiary",
      borderRadius: "4px",
      boxShadow: "0px 12px 34px rgba(170, 99, 255, 0.3)",
      padding: "16px 32px",
      "&:hover:not(:disabled)": {
        variant: "buttons.base.&:hover:not(:disabled)",
        bg: "brandTertiaryHover",
      },
      "&:active": {
        variant: "buttons.base.&:active",
        bg: "brandTertiaryActive",
      },
      ":disabled": {
        variant: "buttons.base.:disabled",
        boxShadow: "none",
        bg: "#eeeeee",
        color: "#aaaaaa",
      },
    },
    reset: {
      textAlign: "inherit",
      padding: 0,
      border: "none",
      borderRadius: 0,
      background: "transparent",
      font: "inherit",
      lineHeight: 1,
      textDecoration: "none",
      cursor: "pointer",
      appearance: "none",
      color: "inherit",
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
  },
};

export default Theme;
