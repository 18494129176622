import React, { useCallback, useContext } from "react";

import { EVENT_NAME } from "./auth/session-provider/session-storage";

export const ProductTypeContext: any = React.createContext(null);

export type ProductTypeContextInner = {
  productType: string;
  defaultBaseProductType: string;
  defaultExecutiveProductType: string;
};

export type ProductTypeContextValue = {
  productType: string | null;
  defaultBaseProductType: string | null;
  defaultExecutiveProductType: string | null;
  productTypeContext: ProductTypeContextInner | null;
  setProductTypeContext: (value: ProductTypeContextInner | null) => void;
  setActiveProductType: (value: string) => void;
};

export const ProductTypeProvider = ({ children }: any) => {
  const [productTypeContext, setProductTypeContext] =
    React.useState<ProductTypeContextInner | null>(null);

  const handleAuthEvent = useCallback(
    (event) => {
      if (
        event.detail.authEventType === "loggedOut" ||
        event.detail.authEventType === "loggedIn"
      ) {
        setProductTypeContext(null);
      }
    },
    [setProductTypeContext]
  );

  React.useEffect(() => {
    window.addEventListener(EVENT_NAME, handleAuthEvent);

    return () => {
      window.removeEventListener(EVENT_NAME, handleAuthEvent);
    };
  }, []);

  const store = {
    productType: productTypeContext?.productType,
    defaultBaseProductType: productTypeContext?.defaultBaseProductType,
    defaultExecutiveProductType:
      productTypeContext?.defaultExecutiveProductType,
    setProductTypeContext,
    // @ts-ignore
    setActiveProductType: (value: string) =>
      setProductTypeContext({
        ...(productTypeContext || ({} as any)),
        productType: value,
      }),
  };

  return (
    <ProductTypeContext.Provider value={store}>
      {children}
    </ProductTypeContext.Provider>
  );
};

export const useProductType: () => ProductTypeContextValue = () => {
  const productType: ProductTypeContextValue = useContext(ProductTypeContext);

  return productType;
};

export const useIsBaseProductType = () => {
  const { productType } = useProductType();

  return productType === "BASE" || productType === "COMPANY";
};

export const useIsExecutiveProductType = () => {
  const { productType } = useProductType();

  return productType === "EXECUTIVE";
};

export const useIsSchoolUserProductType = () => {
  const { productType } = useProductType();

  return productType === "BASE";
};

export const useDefaultBaseProductType = () => {
  const { defaultBaseProductType } = useProductType();
  return defaultBaseProductType;
};

export const useDefaultExecutiveProductType = () => {
  const { defaultExecutiveProductType } = useProductType();
  return defaultExecutiveProductType;
};
