import { css } from "styled-components";

const styledButtonReset = css`
  display: inline-block;
  text-align: inherit;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  font: inherit;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  color: inherit;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default styledButtonReset;
