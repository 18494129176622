import { PanelBase } from "./PanelBase";
import { PanelBody } from "./PanelBody";
import { PanelFooter } from "./PanelFooter";
import { PanelHeader } from "./PanelHeader";
import { PanelHeading } from "./PanelHeading";

export const Panel = {
  Base: PanelBase,
  Header: PanelHeader,
  Heading: PanelHeading,
  Body: PanelBody,
  Footer: PanelFooter,
};

export default Panel;
