import { format, parseJSON } from "date-fns";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import { MoodEmoji } from "../../brand";
import { Text, Flex } from "../../core";

export interface PositivityCompoundChartValue {
  date: string;
  detractors: number;
  neutral: number;
  promoters: number;
  total: number;
  positivity: number;
  tooltip: {
    detractors: number;
    neutral: number;
    promoters: number;
    positivity: string;
    total: number;
  };
}

type PayloadName = keyof Pick<
  PositivityCompoundChartValue,
  "detractors" | "neutral" | "positivity" | "promoters"
>;

type PayloadNameWithEmoji = Exclude<PayloadName, "positivity">;

interface PositivityCompoundChartProps {
  data: PositivityCompoundChartValue[];
  height?: string | number | undefined;
  width?: string | number | undefined;
}

const tooltipMoodEmojiIds: Record<PayloadNameWithEmoji, string> = {
  detractors: "575a9046-e874-4176-b653-ad5acff8db08",
  promoters: "575a9046-e874-4176-b653-ad5acff8db05",
  neutral: "575a9046-e874-4176-b653-ad5acff8db0c",
};

const PositivityCompoundChartTooltip = (
  props: TooltipProps<number, PayloadName>
) => {
  if (!props.active || !props.payload) return null;

  return (
    <div
      style={{
        display: "grid",
        gap: "8px",
        margin: 0,
        padding: 10,
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        whiteSpace: "nowrap",
      }}
    >
      <Text fontFamily="button">
        {props.labelFormatter?.(props.label, props.payload)}
      </Text>
      {[...props.payload].reverse().map((payload) => {
        if (!payload.name) return null;

        const value = payload.payload as PositivityCompoundChartValue;

        if (payload.name === "positivity") {
          return (
            <div key={payload.name}>
              Positivity: {value.tooltip[payload.name]}
            </div>
          );
        }

        return (
          <Flex alignItems="center" key={payload.name}>
            <MoodEmoji
              moodId={tooltipMoodEmojiIds[payload.name] as string}
              label={payload.name}
              size="sm"
            />
            : {value.tooltip[payload.name]}
          </Flex>
        );
      })}
    </div>
  );
};

const StyledPositivityCompoundChart = styled.div`
  .recharts-cartesian-axis-tick-line {
    stroke: rgb(119, 119, 119);
  }
  .recharts-text {
    fill: rgb(51, 51, 51);
    font-size: 11px;
  }
`;

const PositivityCompoundChart = ({
  data,
  width,
  height,
}: PositivityCompoundChartProps) => {
  return (
    <StyledPositivityCompoundChart>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={data}
          margin={{
            top: 32,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <CartesianGrid />
          <YAxis dataKey="positivity" domain={[0, 100]} />
          <XAxis
            label={{ fill: "rgb(119, 119, 119)" }}
            dataKey="date"
            tickFormatter={(value) => format(parseJSON(value), "MMM")}
          />
          <Bar dataKey="detractors" stackId="a" fill="#FF7F81" />
          <Bar dataKey="neutral" stackId="a" fill="#e7e173" />
          <Bar
            dataKey="promoters"
            stackId="a"
            fill="#39CC99"
            radius={[8, 8, 0, 0]}
          />

          <Line dataKey="positivity" stroke="rgb(51, 51, 51)">
            <LabelList dataKey="positivity" position="top" offset={15} />
          </Line>
          <Tooltip
            content={<PositivityCompoundChartTooltip />}
            labelFormatter={(label) => format(parseJSON(label), "MMMM yyyy")}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledPositivityCompoundChart>
  );
};

export default PositivityCompoundChart;