import { InputProps } from "@rebass/forms";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import { TextInput } from "../text-input";

export interface AddressValue {
  line1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface AddressInputProps
  extends Omit<InputProps, "css" | "onChange" | "value"> {
  apiKey: string;
  onChange: (value: AddressValue) => void;
}

const AddressInput = ({ apiKey, onChange, ...props }: AddressInputProps) => {
  const { ref } = usePlacesWidget({
    apiKey,
    onPlaceSelected: (place) => {
      let components: {
        country?: string;
        subpremise?: string;
        street_number?: string;
        route?: string;
        locality?: string;
        administrative_area_level_1?: string;
        postal_code?: string;
      } = {};

      place.address_components.forEach(function (comp: any) {
        comp.types.forEach(function (typeName: any) {
          components[typeName as keyof typeof components] =
            typeName === "country" ? comp.short_name : comp.long_name;
        });
      });

      const address = {
        line1: `${components.subpremise ? components.subpremise + "/" : ""}${
          components.street_number ? `${components.street_number} ` : ""
        }${components.route ? `${components.route}` : ""}`,
        city: components.locality ?? "",
        state: components.administrative_area_level_1 ?? "",
        zip: components.postal_code ?? "",
        country: components.country ?? "",
      };

      onChange(address);
    },
    options: {
      types: ["establishment"],
      fields: ["place_id", "formatted_address", "address_components"],
    },
  });

  return <TextInput {...props} ref={ref} />;
};

export default AddressInput;
