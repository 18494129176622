import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetViewerAccountType = gql`
  query GetViewerAccountType {
    viewer {
      id
      accountType
    }
  }
`;

export const AccountGuard = ({
  children,
  accountTypeWhitelist,
  accountTypeBlacklist,
}: any) => {
  const { loading, error, data } = useQuery(GetViewerAccountType, {
    fetchPolicy: "cache-first",
  });
  if (
    loading ||
    error ||
    !data ||
    (data &&
      (accountTypeBlacklist
        ? accountTypeBlacklist.indexOf(data.viewer.accountType) !== -1
        : accountTypeWhitelist.indexOf(data.viewer.accountType) === -1))
  ) {
    return null;
  }

  return children;
};
