import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import { Flex } from "../../core";

export * from "./ModalCloseButton";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    width: "400px",
    padding: "none",
    border: "none",
    background: "none",
  },
  overlay: {
    background: "rgba(0,0,0,0.95)",
  },
};

export const ModalBase = ({
  isOpen,
  onClose,
  contentLabel,
  ...otherProps
}: any) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={customStyles}
    contentLabel={contentLabel}
    {...otherProps}
  />
);

export const ModalHeader = styled(Flex)`
  padding: 12px;

  &:first-of-type {
    border-top-right-radius: 0.325em;
    border-top-left-radius: 0.325em;
  }

  &:last-of-type {
    border-bottom-right-radius: 0.325em;
    border-bottom-left-radius: 0.325em;
  }
`;

export const ModalBody = styled(Flex)`
  padding: 12px;
  background: #ffffff;

  &:first-of-type {
    border-top-right-radius: 0.325em;
    border-top-left-radius: 0.325em;
  }

  &:last-of-type {
    border-bottom-right-radius: 0.325em;
    border-bottom-left-radius: 0.325em;
  }
`;

export const ModalFooter = styled(Flex)`
  padding: 12px;

  background: #fafafa;
  color: #ffffff;

  &:first-of-type {
    border-top-right-radius: 0.325em;
    border-top-left-radius: 0.325em;
  }

  &:last-of-type {
    border-bottom-right-radius: 0.325em;
    border-bottom-left-radius: 0.325em;
  }
`;

ModalHeader.defaultProps = {
  ...ModalHeader.defaultProps,
  justifyContent: "space-between",
  alignItems: "center",
  tx: "modalHeaders",
  variant: "base",
};

ModalBody.defaultProps = {
  ...ModalBody.defaultProps,
  flexDirection: "column",
};

ModalFooter.defaultProps = {
  ...ModalFooter.defaultProps,
  justifyContent: "flex-end",
  alignItems: "center",
};

export const Modal = {
  Base: ModalBase,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
};

export default Modal;
