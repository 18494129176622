import React from "react";

import { Flex, Box } from "../../";

export type Tab = {
  id?: string;
  label: string;
  disabled?: boolean;
  getUrl: (params: any) => string;
};

export const VerticalTabs = ({
  tabs,
  isTabActive,
  changeTab,
  ...otherProps
}: {
  tabs: Tab[];
  isTabActive: (tab: Tab, tabIdx: number) => any;
  changeTab: (tab: Tab, tabIdx: number) => any;
  [id: string]: any;
}) => (
  <Flex
    alignItems="flex-start"
    flexDirection="row"
    style={{
      borderBottom: "1px solid rgb(214, 223, 239)",
      flex: "1",
    }}
    {...otherProps}
  >
    {tabs
      .filter((tab: Tab) => !tab.disabled)
      .map((tab: Tab, tabIdx: number) => {
        return (
          <Flex
            key={tabIdx}
            mr={2}
            px={2}
            pb={2}
            style={{
              cursor: "pointer",
              position: "relative",
              justifyContent: "center",
            }}
            onClick={() => changeTab(tab, tabIdx)}
          >
            {tab.label}
            {isTabActive(tab, tabIdx) && (
              <Box
                style={{
                  background: "rgb(27, 33, 50)",
                  position: "absolute",
                  bottom: "-2px",
                  width: "100%",
                  height: "3px",
                }}
              ></Box>
            )}
          </Flex>
        );
      })}
  </Flex>
);
