import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split,
  PossibleTypesMap,
  HttpLink,
  TypePolicies,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "apollo-utilities";

import { getGlobalSessionStorage } from "../session-provider/session-storage";
import { AuthLink } from "./AuthLink";
import buildAuthenticationErrorLink from "./buildAuthenticationErrorLink";

export const buildAppCache = ({
  possibleTypes,
  typePolicies,
}: {
  possibleTypes: PossibleTypesMap;
  typePolicies?: TypePolicies;
}) =>
  new InMemoryCache({
    possibleTypes: possibleTypes,
    typePolicies: typePolicies,
  });

export const buildAppClient = ({
  useErrorLink,
  customAuthLink = null,
  appCache,
  blacklistedForBatching = [],
}: {
  useErrorLink: boolean;
  appCache: InMemoryCache;
  customAuthLink?: ApolloLink | null;
  blacklistedForBatching?: string[];
}) => {
  return new ApolloClient({
    cache: appCache,
    link: ApolloLink.from(
      [
        new RetryLink({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: 2,
            retryIf: (error, _operation) => {
              const { operation }: any = getMainDefinition(_operation.query);

              return !!error && operation === "query";
            },
          },
        }),
        useErrorLink && buildAuthenticationErrorLink(),
        customAuthLink || new AuthLink(),
        split(
          ({ query }) => {
            const mainDefinition = getMainDefinition(query);
            const isOperation = mainDefinition.kind === "OperationDefinition";

            if (!isOperation) {
              return true;
            }

            if (
              localStorage.getItem("skodel-apollo-dont-use-batching") === "1"
            ) {
              return true;
            }

            const isQuery = mainDefinition.operation === "query";

            if (!isQuery) {
              return true;
            }

            if (
              mainDefinition.name &&
              blacklistedForBatching.indexOf(mainDefinition.name.value) !== -1
            ) {
              return true;
            }

            return false;
          },
          new HttpLink({
            uri: () =>
              `${getGlobalSessionStorage().getBaseHost()}/${getGlobalSessionStorage().getProductPathSegment()}/graphql`,
          }), // if the above is not met
          new BatchHttpLink({
            uri: () =>
              `${getGlobalSessionStorage().getBaseHost()}/${getGlobalSessionStorage().getProductPathSegment()}/graphql`,
          }) // otherwise, batching is fine
        ),
      ].filter((linkValue: any) => linkValue !== false) as ApolloLink[]
    ),
  });
};

export default buildAppClient;
