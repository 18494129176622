import gql from "graphql-tag";

import { getGlobalSessionStorage } from "../session-storage";
import checkToken from "../util/checkToken";
import handleLoginError from "./handleLoginError";

export const loginWithCredentials = async (email: String, password: String) => {
  try {
    const { data } = await getGlobalSessionStorage().clients.auth.mutate({
      variables: {
        email,
        password,
      },
      mutation: gql`
        mutation LoginWithCredentials($email: String!, $password: String!) {
          loginWithCredentials(email: $email, password: $password) {
            __typename
            ... on Operation {
              success
            }
            ... on SuccessfulAuthentication {
              token
              refreshToken
            }
          }
        }
      `,
    });

    if (!data.loginWithCredentials.success) {
      return handleLoginError(data.loginWithCredentials);
    } else {
      if (await checkToken(data.loginWithCredentials.token)) {
        getGlobalSessionStorage().login(
          data.loginWithCredentials.token,
          data.loginWithCredentials.refreshToken
        );
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};

export default loginWithCredentials;
