import styled from "styled-components";

import { Flex } from "../../core";

export const MaxWidthContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  max-width: 1080px;
  width: 100%;
  padding: 16px;
`;

export default MaxWidthContainer;
