// @ts-ignore
import GraphiQL from "graphiql-with-extensions";
import "graphiql-with-extensions/graphiqlWithExtensions.css";
import React from "react";
import styled from "styled-components";

import { Flex } from "../core";
import { getGlobalSessionStorage } from "./auth";

const FullWidthGraphiQLWrapper = styled(Flex)`
  .graphiql-container {
    min-height: 100vh;
    position: absolute;
  }
`;
function graphQLFetcher(graphQLParams: any) {
  const sessionStorage = getGlobalSessionStorage();

  return fetch(
    `${sessionStorage.getBaseHost()}/${sessionStorage.getProductPathSegment()}/graphql`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-SKODEL-GLOBAL-AUTH": sessionStorage.token,
      },
      body: JSON.stringify(graphQLParams),
    }
  ).then((response: any) => response.json());
}

export const GraphiQLView = () => (
  <FullWidthGraphiQLWrapper>
    <GraphiQL fetcher={graphQLFetcher} editorTheme="solarized dark" />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.23.0/theme/solarized.css"
    />
  </FullWidthGraphiQLWrapper>
);

export default GraphiQLView;
