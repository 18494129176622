import { useQuery } from "@apollo/client";
import React from "react";
import ReactAvatar, { ReactAvatarProps } from "react-avatar";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useNameQueryFromContext } from "../../name-helpers/query-context";

export type AvatarProps = ReactAvatarProps & {
  firstName?: string;
  lastName?: string;
  label?: string;
  id?: string;
  avatarLabel?: string;
};

export const Avatar: React.FC<AvatarProps & { active?: boolean }> = ({
  firstName,
  lastName,
  label,
  id,
  style,
  active = false,
  avatarLabel,
  ...otherProps
}) => (
  <ReactAvatar
    alt={label || `${firstName} ${lastName}`}
    name={avatarLabel ? undefined : label || `${firstName} ${lastName}`}
    value={avatarLabel}
    size={"32px"}
    round
    color={active ? "rgb(152, 66, 255)" : "rgb(242, 239, 254)"}
    fgColor={active ? "#FFFFFF" : "#333"}
    style={{
      fontFamily: "Nunito Sans Bold",
      transition: "0.12s ease-in",
      ...style,
    }}
    {...otherProps}
  />
);

export const AvatarBrand: React.FC<AvatarProps> = (props) => (
  <Avatar color="rgb(152, 66, 255)" fgColor={"#FFFFFF"} {...props} />
);

const AvatarLinkAnchor = styled(Link)`
  &:hover,
  &:active {
    .avatar-inner {
      text-decoration: underline;
    }
  }
`;

export const AvatarLink: React.FC<
  AvatarProps & { active?: boolean; to: any; onClick?: any }
> = ({ to, style, onClick, ...otherProps }) => (
  <AvatarLinkAnchor
    to={to}
    aria-label={
      otherProps.label || `${otherProps.firstName} ${otherProps.lastName}`
    }
    onClick={onClick}
  >
    <Avatar className="avatar-inner" {...otherProps} aria-hidden />
  </AvatarLinkAnchor>
);

export const LazyAvatar: React.FC<AvatarProps & { userId: string }> = ({
  userId,
  ...otherProps
}: {
  userId: string;
  label?: string;
  id?: string;
  avatarLabel?: string;
} & AvatarProps) => {
  const GetUserName = useNameQueryFromContext("GetUserName");

  const { error, data, loading } = useQuery(GetUserName, {
    variables: {
      userId,
    },
    fetchPolicy: "cache-first",
  });

  if (loading || error || !data || !data.user) {
    return <BlankAvatar />;
  }

  return (
    <Avatar
      firstName={data.user.firstName}
      lastName={data.user.lastName}
      {...otherProps}
    />
  );
};
export const BlankAvatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  return <Avatar firstName="?" lastName="?" {...props} />;
};

export default Avatar;
