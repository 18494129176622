import styled from "styled-components";

const TableBase = styled.table`
  width: 100%;
  border-spacing: 0px;
  background: #ffffff;
  border-radius: 0.325em;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-top-right-radius: 0.325em;
`;

const TableHeader = styled.thead`
  border-top-right-radius: 0.325em;
`;

const TableHeaderRow = styled.tr`
  color: rgb(30, 38, 63);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-top-right-radius: 0.325em;

  &:first-of-type {
    border-top-left-radius: 0.325em;
  }

  &:last-of-type {
    border-top-right-radius: 0.325em;
  }
`;

const TableHeaderData = styled.th`
  padding: 12px 24px;

  background: #f9fafb;
  text-align: left;
  font-weight: bold;
`;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr`
  &:nth-of-type(even) {
    background: #fafafa;
  }
`;

const TableBodyData = styled.td`
  padding: 12px 24px;
`;

export const Table = {
  Base: TableBase,
  Header: TableHeader,
  HeaderRow: TableHeaderRow,
  HeaderData: TableHeaderData,
  Body: TableBody,
  BodyRow: TableBodyRow,
  BodyData: TableBodyData,
};
