import React from "react";
import { useTranslation } from "react-i18next";

import { Label } from "../../";

export const UserAccountType = ({ accountType }: any) => {
  const { t } = useTranslation();

  if (accountType === "USER") {
    return (
      <>{t("UserAccountType.respondent", { defaultValue: "Respondent" })}</>
    );
  }

  if (accountType === "WELLBEING_LEADER") {
    return (
      <>
        {t("UserAccountType.wellbeing_leader", {
          defaultValue: "Wellbeing Leader",
        })}
      </>
    );
  }

  if (accountType === "WELLBEING_FACILITATOR") {
    return (
      <>
        {t("UserAccountType.wellbeing_facilitator", {
          defaultValue: "Wellbeing Facilitator",
        })}
      </>
    );
  }

  if (accountType === "ORG_MANAGER") {
    return (
      <>
        {t("UserAccountType.organization_manager", {
          defaultValue: "Organization Manager",
        })}
      </>
    );
  }

  if (accountType === "DISTRIBUTOR") {
    return (
      <>{t("UserAccountType.distributor", { defaultValue: "Distributor" })}</>
    );
  }

  if (accountType === "GLOBAL_ADMINISTRATOR") {
    return (
      <>
        {t("UserAccountType.global_administrator", {
          defaultValue: "Global Administrator",
        })}
      </>
    );
  }

  return <>{t("UserAccountType.unknown", { defaultValue: "Unknown" })}</>;
};

export const StyledUserAccountType = ({ accountType }: any) => {
  return (
    <Label>
      <UserAccountType accountType={accountType} />
    </Label>
  );
};

export default UserAccountType;
