import styled from "styled-components";

import { Panel, FlexProps } from "../..";

export const CheckInBase: any = styled(Panel.Base)`
  animation: fadein 0.1s linear 1 forwards;
  width: 100%;
  border: ${(
    props: FlexProps & {
      isRestricted?: boolean;
      isSensitive?: boolean;
    }
  ) =>
    props.isRestricted
      ? "2px solid red"
      : props.isSensitive
      ? "2px solid rgb(152,66,255)"
      : "initial"};
`;

export default CheckInBase;
