import { DocumentNode } from "graphql";
import React from "react";

const initialValue: {
  GetViewerIdForComments?: DocumentNode;
  GetCommentsForCheckIn?: DocumentNode;
  GetMyReactionsForCheckIn?: DocumentNode;
  GetConcernsForCheckIn?: DocumentNode;
  GetMyConcernDetailsForOrganization?: DocumentNode;
  GetCheckInDescriptionData?: DocumentNode;
  GetTagsForCheckIn?: DocumentNode;

  CommentOnCheckIn?: DocumentNode;
  DeleteCommentFromCheckIn?: DocumentNode;
  ReactToComment?: DocumentNode;
  DeleteReaction?: DocumentNode;
  ReactToCheckIn?: DocumentNode;
} = {};

export const CheckInRenderQueryContext = React.createContext(initialValue);

export const useCheckInQueryFromContext: (queryName: string) => DocumentNode = (
  queryName
) => {
  const ctx = React.useContext(CheckInRenderQueryContext);
  // @ts-ignore
  const queryValue = ctx[queryName as any];

  if (!queryValue) {
    throw new Error(
      `Could not initialize ${queryName}, GraphQL query not setup in CheckInRenderQueryContext`
    );
  }

  return queryValue;
};
