import gql from "graphql-tag";

import { AuthLink } from "../../apollo/AuthLink";
import { buildAppClient, buildAppCache } from "../../apollo/buildAppClient";

export const checkToken: (token: string) => Promise<boolean> = async (
  token
) => {
  try {
    const appCache = buildAppCache({ possibleTypes: {} });
    const newClient = buildAppClient({
      appCache,
      useErrorLink: false,
      customAuthLink: new AuthLink({
        checkHasAuthenticatedSuccessfully: false,
        forceToken: token,
      }),
    });
    const { data } = await newClient.query({
      query: gql`
        query AuthenticationPing {
          ping
        }
      `,
    });

    return data && data.ping === "pong";
  } catch (e) {
    return false;
  }
};

export default checkToken;
