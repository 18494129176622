import styled from "styled-components";

import { Heading } from "../../core";

export const PageHeading = styled(Heading)`
  flex: 1;
  font-family: "Nunito Sans Bold" !important;
  font-weight: normal;
`;

export default PageHeading;
