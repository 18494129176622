import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

import { Text, Modal, ModalCloseButton, InlineLoader, Flex } from "../../";

export const EmptyModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={t("EmptyModal.loading", {
        defaultValue: "Loading..",
      })}
    >
      <Modal.Header>
        <Text>
          {t("EmptyModal.loading_body", {
            defaultValue: "Loading..",
          })}
        </Text>
        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <InlineLoader />
      </Modal.Body>
    </Modal.Base>
  );
};

const ClipLoader_ = styled(ClipLoader)`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  width: 2em;
  height: 2em;
`;

const ClipLoaderContainer = styled(Flex)`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 10s;
`;

const overlayStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    width: "400px",
    padding: "none",
    border: "none",
    background: "none",
  },
  overlay: {
    background: "rgba(0,0,0,0.95)",
    borderColor: "#ffffff",
  },
};

export const EmptyOverlayLoader = ({ isOpen, onClose }: any) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={overlayStyles}
      contentLabel={"Loading modal"}
    >
      <ClipLoaderContainer alignItems="center" justifyContent="center">
        <ClipLoader_ color="rgba(255,255,255,0.8)" />
      </ClipLoaderContainer>
    </ReactModal>
  );
};
