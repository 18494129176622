import React from "react";

import { Heading } from "../../core";

export const PanelHeading = (otherProps: any) => (
  <Heading
    fontSize="2"
    fontWeight="200"
    fontFamily="Nunito Sans Bold"
    {...otherProps}
  />
);

export default PanelHeading;
