import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { getGlobalSessionStorage } from "../session-provider/session-storage";

export const buildAnalyticsClient = () => {
  const cache = new InMemoryCache({});

  return new ApolloClient({
    cache,
    link: new HttpLink({
      uri: () => `${getGlobalSessionStorage().getBaseHost()}/analytics/graphql`,
    }),
  });
};

export default buildAnalyticsClient;
