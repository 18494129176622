import { Cross } from "@styled-icons/icomoon/Cross";
import React from "react";

import { Flex } from "../../core";

export const Checkbox = ({ checked, onChange, ...otherProps }: any) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    bg={checked ? "#57a8da" : "#ffffff"}
    color="white"
    onClick={() => onChange(!checked)}
    style={{
      borderRadius: "3px",
      width: "16px",
      height: "16px",
      cursor: "pointer",
      border: checked ? "none" : "2px solid #979797",
      lineHeight: "0",
      ...(checked
        ? {
            background: "rgb(152,66,255)",
            boxShadow: "1px 3px 2px 0 rgba(170,99,255,.3)",
          }
        : {}),
    }}
    {...otherProps}
  >
    {checked && <Cross width="6px" height="6px" />}
  </Flex>
);

export default Checkbox;
