import { DocumentNode } from "graphql";
import React from "react";

const initialValue: {
  GetBrandLogo?: DocumentNode;
  GetUnreadNotificationCount?: DocumentNode;
  GetUserAccountImpersonation?: DocumentNode;
  GetViewerNotificationsForDropdown?: DocumentNode;
  AcknowledgeNotifications?: DocumentNode;
  InteractWithNotification?: DocumentNode;

  RecordPageView?: DocumentNode;
  StopImpersonating?: DocumentNode;
  UpdateLanguage?: DocumentNode;
} = {};

export const BaseQueryContext = React.createContext(initialValue);

export const useBaseQueryFromContext: (queryName: string) => DocumentNode = (
  queryName
) => {
  const ctx = React.useContext(BaseQueryContext);
  // @ts-ignore
  const queryValue = ctx[queryName as any];

  if (!queryValue) {
    throw new Error(
      `Could not initialize ${queryName}, GraphQL query not setup in BaseQueryContext`
    );
  }

  return queryValue;
};
