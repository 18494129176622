import { ThumbsDown as ThumbsDownOutlineIcon } from "@styled-icons/fa-regular/ThumbsDown";
import { ThumbsUp as ThumbsUpOutlineIcon } from "@styled-icons/fa-regular/ThumbsUp";
import { ThumbsDown as ThumbsDownIcon } from "@styled-icons/fa-solid/ThumbsDown";
import { ThumbsUp as ThumbsUpIcon } from "@styled-icons/fa-solid/ThumbsUp";
import React from "react";
import styled from "styled-components";

import { Flex, FlexProps, Text } from "../../core";

const ThumbsUp = styled(ThumbsUpIcon)`
  width: 1em;
  height: 1em;
  cursor: pointer;
`;

const ThumbsDown = styled(ThumbsDownIcon)`
  width: 1em;
  height: 1em;
  cursor: pointer;
`;

const ThumbsUpOutline = styled(ThumbsUpOutlineIcon)`
  width: 1em;
  height: 1em;
  cursor: pointer;
`;

const ThumbsDownOutline = styled(ThumbsDownOutlineIcon)`
  width: 1em;
  height: 1em;
  cursor: pointer;
`;

export const ThumbRating = ({
  value,
  onClick,
  ...props
}: {
  value: "UP" | "DOWN" | null;
  onClick: (value: string | null) => any;
} & FlexProps) => {
  const updateRating = (ratingValue: string) => () => {
    onClick(ratingValue);
  };

  return (
    // @ts-ignore
    <Flex {...props} fontSize="0.8em">
      <Text mr={"2"} color="grey">
        Is this content useful?
      </Text>
      <Flex
        style={{
          display: "grid",
          gridGap: "8px",
          gridTemplateColumns: "1fr 1fr",
        }}
        color="#555"
      >
        {value === "UP" ? (
          <ThumbsUp onClick={updateRating("UP")} />
        ) : (
          <ThumbsUpOutline onClick={updateRating("UP")} />
        )}
        {value === "DOWN" ? (
          <ThumbsDown onClick={updateRating("DOWN")} />
        ) : (
          <ThumbsDownOutline onClick={updateRating("DOWN")} />
        )}
      </Flex>
    </Flex>
  );
};

export default ThumbRating;
