import { toast } from "react-toastify";

export const handleLoginError = (err: any) => {
  if (err.__typename === "InvalidGoogleToken") {
    toast.error("There was an issue signing in with Google. Please try again.");
  } else if (err.__typename === "InvalidEmail") {
    toast.error("Invalid email. Please try again.");
  } else if (err.__typename === "NotConfiguredForGoogle") {
    toast.error(
      "This account is not authorised for Google login. Please contact the person who manages your account."
    );
  } else if (err.__typename === "InvalidPassword") {
    toast.error("Invalid password. Please try again.");
  } else if (err.__typename === "InvalidCredentials") {
    toast.error("Invalid credentials. Please try again.");
  } else if (err.__typename === "EmptyPassword") {
    toast.error("Your password cannot be empty. Please try again.");
  } else if (err.__typename === "AccountHasntOnboarded") {
    toast.error(
      "Could not login. Please check your email and password and try again."
    );
  } else if (err.__typename === "AccountTemporarilyDisabled") {
    toast.error(
      "This account has been temporarily disabled. Please try again later."
    );
  } else if (err.__typename === "AuthenticationRequires2FA") {
    // TODO
  } else {
    toast.error(
      "Could not login. Please check your email and password and try again."
    );
  }
};

export default handleLoginError;
